const url = {
  login: "/admin/login",
  getAllUsers: "/admin/getUsers",
  getUsersByStatus: "/admin/getUserByStatus"

}


export default url;

// /admin/getUserByStatus?status=verified
// /admin/getUsers?pageSize=10&pageNumber1