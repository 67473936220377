import { faDashboard, faUser } from "@fortawesome/free-solid-svg-icons"
import IRouter from "../interfaces/IRouter"
import Dashboard from "../View/Private/Dashboard"

export const baseUrl = "/admin"

const adminRoutes: IRouter[] = [
    {
        path: "dashboard/user/all",
        navbarShow: true,
        element: Dashboard,
        name: "Dashboard",
        icon: faUser
    },



]

export default adminRoutes