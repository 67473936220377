import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import adminRoutes, { baseUrl } from '../routes/routes';
import SideBar from './SideBar/SideBar';


export default function PrivateRoutes() {

    return (
        <>

            <SideBar
                menuData={adminRoutes}
                panelName="Admin"
                baseUrl={baseUrl}
            />

            <Container>
                <Outlet />
            </Container>
        </>
    )
}