import axios from 'axios';
import React, { useState } from 'react'
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Auth from '../Auth/auth';
import { AuthService } from '../service/auth.service';

const Login = () => {

    const [loginData, setLoginData] = useState<any>({
        email: "",
        password: "",
    });

    const onChangeValue = (e: any) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };


    const navigate = useNavigate();

    const onLoginClick = async (e: any) => {
        e.preventDefault();
        await AuthService.login(({
            email: loginData.email,
            password: loginData.password,
            role: "Admin"
        }))
            .then(async (res) => {
                if (res.status === 200) {
                    await Auth.authenticate();
                    localStorage.setItem("authKey", res.data)
                    navigate('/admin/dashboard/user/all')
                    toast("Login Success")
                }
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    console.error(err);
                } else if (err.response.status === 500) {
                    alert(err.response.data)
                }
            })
    };


    // const onLoginClick = () => {
    //     axios({
    //         method: "post",
    //         url: "http://localhost:3200/api/v1/admin/login",
    //         data: {
    //             email: loginData.email,
    //             password: loginData.password,
    //             role: "Admin",
    //         },
    //     }).then(
    //         (res) => {
    //             if (res.status === 200) {
    //                 toast.success("successful");
    //                 sessionStorage.setItem("authKey", res.data);
    //                 Auth.authenticate();
    //                 navigate("/dashboard");
    //             } else {
    //                 console.log("failed to login");
    //             }
    //         },
    //         (error) => {
    //             toast.error(error.message);
    //             console.log(error);
    //         }
    //     );
    // };
    return (
        <>
            <Container>
                <Image src={"https://pspcl.in/assets/images/logo.png"} />

                <h1 className="text-center font-weight-bold xcn-login-header m-3">
                    Stats Login - Admin Panel
                </h1>
                <Row className="justify-content-md-center  mt-4">
                    <Col xs lg="2" />
                    <Col md="auto">
                        <Card className="p-3" style={{ maxWidth: "25rem", minWidth: "25vw" }}>
                            <>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        name="email"
                                        onChange={onChangeValue}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingPassword"
                                    label="Password"
                                    onChange={onChangeValue}
                                >
                                    <Form.Control type="password" placeholder="Password" name="password" />
                                </FloatingLabel>
                            </>
                            <Button
                                variant="primary"
                                type="submit"
                                className="mt-4"
                                size="lg"
                                onClick={onLoginClick}
                            >
                                <span className="xcn-text-14">
                                    Login
                                </span>
                            </Button>
                        </Card>
                    </Col>
                    <Col xs lg="2" />
                </Row>
            </Container>
        </>
    )
}

export default Login