import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { AdminService } from '../../service/admin.service';
import UserStats from './UserStats/UserStats'
import Select from "react-select"
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import StatusTable from '../../components/Table/status.table';
import moment from 'moment';
import { CSVDownload, CSVLink } from 'react-csv';


const Dashboard = () => {

    const statusData = [
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "General Info",
            value: "geninfo"
        },
        {
            label: "Education Info",
            value: "eduinfo"
        },
        {
            label: "Uploads",
            value: "uploaded"
        },
        {
            label: "Verified Users",
            value: "verified"
        },
        {
            label: "Submitted",
            value: "submitted"
        },
    ]


    const [userData, setUserData] = useState<any>();
    const [statusValue, setStatusValue] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [selectedUserStatus, setSelectedUserStatus] = useState<any>();
    const [downloadCsvFormat, setDownloadCsvFormat] = useState<any[]>([]);
    const [selectedData, setSelectedData] = useState<any>(statusData);





    const getAllUsers = async () => {
        await AdminService.getAllUsers("10", "1").then((res) => {
            if (res.status === 200) {
                setUserData(res.data)
            }
        })
    }


    const handleStatus = (status: any) => {
        setSelectedUserStatus(status);
    }

    const createDownloadCSVFormat = (statusdata: any) => {
        let csvFileData;
        if (statusdata && statusdata.users.length > 0) {
            csvFileData = statusdata.users.map((data: any, index: number) => {
                return {
                    Name: data.name,
                    "Registration Number": data.registerationNumber,
                    Email: data.email,
                    Phone: data.phone,
                    Status: data.status,
                    "City 1": data.cityChoices[0] || "Not Selected",
                    "City 2": data.cityChoices[1] || "Not Selected",
                    "Date of Birth": data.dob ? moment(data.dob).format("Do MMMM YYYY") : "Not Available",
                    "Fee Amount": data.transactionDetails ? (data.transactionDetails.currency + " " + String(data.transactionDetails.amount / 100)) : "0",
                    "Razorpay Transaction Id": data.transactionDetails ? (data.transactionDetails.orderId) : "NA",
                    "Trans. Date and Time": data.transactionDetails ? moment(data.transactionDetails.updatedAt).format("Do MMMM YYYY hh:mm:ss a") : "NA",

                }

            });
        }
        setDownloadCsvFormat(csvFileData);
    }


    const changeUserStatus = async (status: any) => {
        await toast.promise(AdminService.getUserByStatus(status).then(res => {
            if (res.status === 200) {
                setStatus(res.data.users)
                createDownloadCSVFormat(res.data);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(e => {
            console.error(e);
        }), {
            pending: "Fetching Records",
            success: 'Records Fetched 👌',
            error: 'Request Rejected 🤯'
        })
    }





    useEffect(() => {
        if (selectedUserStatus)
            changeUserStatus(selectedUserStatus.value)
    }, [selectedUserStatus])

    useEffect(() => {
        getAllUsers();


    }, [])




    return (
        <>
            <div className="mt-4">
                <UserStats stats={userData} />
            </div>
            <div className='mt-4 d-flex justify-content-between align-items-center'>
                <div>
                    <h3 className='fw-bold'>User Data</h3>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <Select
                        placeholder="Select Status"
                        options={statusData}
                        onChange={handleStatus}
                    />
                    {downloadCsvFormat && downloadCsvFormat.length > 0 ?
                        <CSVLink
                            data={downloadCsvFormat}
                            filename={selectedUserStatus + ".csv"}
                        >
                            <Button className="ms-4"> Download CSV <FontAwesomeIcon icon={faDownload} /> </Button>
                        </CSVLink>
                        : <Button variant="secondary ms-4" disabled>Download CSV <FontAwesomeIcon icon={faDownload} /> </Button>}
                </div>
            </div>
            <div className='mt-4'>
                <Card className="shadow p-3 mb-5 bg-white rounded">
                    <Card.Body>
                        <StatusTable tableData={status} />
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default Dashboard