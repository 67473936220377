import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'
import { Table } from 'react-bootstrap'

interface IStatusTable {
    tableData: any
}

const StatusTable = ({ tableData }: IStatusTable) => {
    return (
        <Table bordered hover size="sm">
            <thead>
                <tr>
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Reg No.</th>
                    <th>Phone</th>
                    <th>Created On</th>
                    <th>Appli. No.</th>
                    <th>Fee Status</th>
                </tr>
            </thead>
            <tbody>
                {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td className="font-weight-bold">
                                {data.name}
                            </td>
                            <td>
                                {data.email}
                            </td>
                            <td>
                                {data.registerationNumber}
                                {/* <FontAwesomeIcon icon={faEye} onClick={() => history.push('/viewuser/' + data._id)} /> */}
                                {/* <FontAwesomeIcon icon={faEye} /> */}
                            </td>
                            <td>
                                {data.phone}
                            </td>

                            <td>
                                {moment(data.createdAt).format("DD/MM/YY")}

                            </td>
                            <td className="fw-bold">
                                {data.applicationNumber ? data.applicationNumber : "--"}
                            </td>

                            <td>
                                {data.feeStatus ? "Paid" : "Unpaid"}
                            </td>
                        </tr>
                    )
                }) : "No data found"}
            </tbody>
        </Table>
    )
}

export default StatusTable