import React, { useState, useEffect } from "react";
import "./UserStats.css";
import XadmContainer from "../../../components/Container/Container";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCodeFork, faDatabase, faDeleteLeft, faHourglass, faIndianRupeeSign, faMoneyBill, faTicket, faUser, faUserCheck, faUserGroup } from "@fortawesome/free-solid-svg-icons";



export default function UserStats({ stats }) {

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon icon={faCodeFork} style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.totalUsers ? stats.totalUsers : 0}</span>
                    <span className="fw-bold">Total Users</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon icon={faHourglass} className="text-primary" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.pendingCount ? stats.pendingCount : 0}</span>
                    <span className="fw-bold">Pending</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon icon={faUser} className="text-secondary" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.geninfoCount ? stats.geninfoCount : 0}</span>
                    <span className="fw-bold">Gen Info</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon icon={faBook} className="text-info" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.eduinfoCount ? stats.eduinfoCount : 0}</span>
                    <span className="fw-bold">Edu Info</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">

                  <FontAwesomeIcon icon={faUserCheck} className="text-warning" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">

                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.verifiedCount ? stats.verifiedCount : 0}</span>
                    <span className="fw-bold">Verified</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">

                  <FontAwesomeIcon icon={faUserGroup} className="text-success" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.submittedCount ? stats.submittedCount : 0}</span>
                    <span className="fw-bold">Submitted</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">

                  <FontAwesomeIcon icon={faIndianRupeeSign} className="text-info" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.paymentRecieved ? stats.paymentRecieved : 0}</span>
                    <span className="fw-bold">Payment Requests</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card style={{ height: "8rem" }} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">

                  <FontAwesomeIcon icon={faMoneyBill} className="text-danger" style={{ fontSize: 30, opacity: .8 }} />
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <span className="fw-bold text-muted" style={{ fontSize: "30px" }}>{stats && stats.rejectedPayments ? stats.rejectedPayments : 0}</span>
                    <span className="fw-bold">Payments Rejected</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
