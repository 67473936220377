import makeRequest from "../api/makeRequest";
import { RequstMethods } from "../api/requestMethode";
import url from "../api/urls";

export class AdminService {
    static async getAllUsers(pageSize: any, pageNumber: any) {
        return await makeRequest(url.getAllUsers + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber, RequstMethods.GET)
    }

    static async getUserByStatus(status: any) {
        return await makeRequest(url.getUsersByStatus + "?status=" + status, RequstMethods.GET)
    }
}